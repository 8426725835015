import React, { useEffect } from 'react';
import { escToClose } from 'utils/functions';

import ModalLayout from '../ModalLayout/ModalLayout';

import {
  ContentContainer,
  IconContainer,
  RowContainer,
  Text,
  PerkText,
  LineBreak,
  PerkTextBold
} from './index.styled';

import { PurchasePerksType } from 'modules/artist/types';
import { getPerkIcon } from '../SongProfileIconsSection/SongProfileIconsSection';

type BenefitsModalProps = {
  onClose: () => void;
  perks: PurchasePerksType[];
};

const InfoBenefitsModal = ({ onClose, perks }: BenefitsModalProps) => {

  useEffect(escToClose(onClose), []);

  return (
    <ModalLayout padding={true} onClose={onClose} title={'info & benefits'}>
      <ContentContainer>
        {perks
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((p, key) => {
            return (
              <RowContainer key={key}>
                <IconContainer>{getPerkIcon(p)}</IconContainer>
                <Text text={p.description.replaceAll('|', '')} />
              </RowContainer>
            );
          })}

        <LineBreak />

        <PerkTextBold text={'How Benefits Are Awarded'} />
        <PerkText>- When you make a purchase, you receive <PerkTextBold text={'all benefits up to and including that price point.'} /></PerkText>
        <PerkText>- If you make a second purchase at a higher price point, you unlock any new benefits associated with that tier, but <PerkTextBold text={'you will not receive duplicates of benefits already claimed in your first transaction.'} /></PerkText>

      </ContentContainer>
    </ModalLayout>
  );
};

export default InfoBenefitsModal;
